<template>
  <v-card class="py-3" flat outlined>
    <v-card-title v-if="label">
      {{ label }}
    </v-card-title>
    <v-layout v-if="fileLocalUrl" justify-center>
      <image-expansion :src="fileLocalUrl" title="Pré-visualização" />
    </v-layout>
    <v-row justify="center" class="mt-3">
      <slot name="additionalInfo" />
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <v-btn v-if="value" color="error" link @click="clearFile">
          Limpar
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          :loading="uploading"
          color="success"
          @click="$refs.inputUpload.click()"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>
      <input
        v-show="false"
        ref="inputUpload"
        type="file"
        :accept="inputAccept"
        @change="load"
      />
    </v-row>
  </v-card>
</template>

<script>
import imageCompression from "browser-image-compression";

export default {
  props: {
    value: {
      type: [Blob, File],
      default: {},
    },
    label: {
      type: String,
      default: "",
    },
    inputAccept: {
      type: String,
      default: "image/jpeg,image/gif,image/png,application/pdf",
    },
  },
  data: () => ({
    file: "",
    uploading: false,
  }),
  computed: {
    buttonText() {
      return (this.value ? "Alterar" : "Novo") + " Arquivo";
    },
    fileLocalUrl() {
      return this.value ? URL.createObjectURL(this.value) : "";
    },
  },
  methods: {
    load() {
      if (this.$refs.inputUpload.files) {
        this.file = this.$refs.inputUpload.files[0];
        const fr = new FileReader();
        fr.readAsDataURL(this.file);
        fr.addEventListener("load", async () => {
          if (this.file.type === "application/pdf") {
            this.$emit("input", this.file);
          } else {
            const compressedImage = await imageCompression(this.file, {
              maxSizeMB: 1,
              useWebWorker: true,
            });
            this.$emit("input", compressedImage);
          }
        });
      }
    },
    openFile() {
      window.open(this.fileLocalUrl);
    },
    clearFile() {
      this.$emit("input", null);
    },
  },
};
</script>

<style></style>
