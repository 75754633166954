<template>
  <div>
    <v-row align="center" class="pa-4">
      <span class="text-h6">{{ shortQuestion }}</span>
      <icon-with-question-explanation
        :explanation="explanation"
        :title="shortQuestion"
      />
    </v-row>
    <v-textarea
      :value="value"
      filled
      placeholder="Digite aqui..."
      @input="(newValue) => $emit('input', newValue)"
    />
  </div>
</template>

<script>
import IconWithQuestionExplanation from "./IconWithQuestionExplanation";
export default {
  name: "QuestionTextAreaWithExplanation",
  components: {
    IconWithQuestionExplanation,
  },
  props: {
    shortQuestion: {
      type: String,
      required: true,
    },
    explanation: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
