<template>
  <div>
    <v-switch
      :value="value.mulher"
      label="É mulher?"
      @change="(newValue) => handleChange('mulher', newValue)"
    />
    <div v-show="value.mulher">
      <v-switch
        :value="value.estaGravida"
        label="Está grávida?"
        @change="(newValue) => handleChange('estaGravida', newValue)"
      />
      <v-text-field
        v-show="value.estaGravida"
        v-mask="'#'"
        :value="value.tempoDeGravidez"
        label="Quanto tempo? (em meses)"
        placeholder="Meses"
        @change="(newValue) => handleChange('tempoDeGravidez', newValue)"
      />
      <div v-show="!value.estaGravida">
        <v-switch
          :value="value.possuiDiu"
          label="Utiliza DIU?"
          @change="(newValue) => handleChange('possuiDiu', newValue)"
        />
        <v-text-field
          v-show="value.possuiDiu"
          :value="value.qualDiu"
          label="Qual o tipo?"
          placeholder="Cobre, prata ou outro..."
          @change="(newValue) => handleChange('qualDiu', newValue)"
        />

        <v-switch
          :value="value.haChancesDeEstarGravida"
          label="Risco de estar grávida?"
          @change="
            (newValue) => handleChange('haChancesDeEstarGravida', newValue)
          "
        />
        <v-switch
          :value="value.utilizaMetodosContraceptivos"
          label="Utiliza métodos contraceptivos?"
          @change="
            (newValue) => handleChange('utilizaMetodosContraceptivos', newValue)
          "
        />
        <v-text-field
          v-show="value.utilizaMetodosContraceptivos"
          :value="value.quaisMetodosContraceptivos"
          label="Qual método contraceptivo?"
          placeholder="Exemplo: anticoncepcional"
          @change="
            (newValue) => handleChange('quaisMetodosContraceptivos', newValue)
          "
        />
      </div>
      <v-switch
        :value="value.possuiImplanteDeSilicone"
        label="Possui alguma prótese de silicone?"
        @change="
          (newValue) => handleChange('possuiImplanteDeSilicone', newValue)
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "WomanQuestions",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    question: {},
  }),
  methods: {
    handleChange(field, value) {
      this.$emit("input", { ...this.value, [field]: value });
    },
  },
};
</script>

<style scoped></style>
