<template>
  <v-container>
    <step-buttons-therapy
      class="my-2"
      @click-next="submit"
      @click-back="back"
    />
    <v-card class="rounded-10 mt-3" outlined>
      <v-card-title class="secondary--text title">
        Anamnese do paciente
      </v-card-title>
      <v-card-text>
        <v-row v-if="consentTermModelFile" justify="center" class="mt-2 mb-3">
          <v-btn color="secondary" @click="openConsentTermModel">
            Abrir Modelo do Termo de Consentimento
          </v-btn>
        </v-row>
        <v-form ref="form" class="mx-5">
          <v-switch
            v-model="question.utilizaDispositivoComBateriasNoCorpo"
            label="Utiliza marca-passo ou qualquer dispositivo que use baterias no corpo?"
          />
          <v-text-field
            v-show="question.utilizaDispositivoComBateriasNoCorpo"
            v-model="question.qualDispositivoComBateriasNoCorpo"
            label="Quais?"
          />
          <v-switch
            v-model="question.possuiHistoricoDeCancer"
            label="Possui histórico de câncer?"
          />
          <v-text-field
            v-show="question.possuiHistoricoDeCancer"
            v-model="question.descricaoHistoricoDeCancer"
            label="Fale mais sobre"
          />
          <v-switch
            v-model="question.recebeuQuimioterapiaOuRadioterapia"
            label="Já recebeu quimioterapia e/ou radioterapia?"
          />
          <v-text-field
            v-show="question.recebeuQuimioterapiaOuRadioterapia"
            v-model="question.quandoQuimioterapiaOuRadioterapia"
            label="Quando?"
          />
          <woman-questions v-model="question" />
          <v-divider class="mb-4" />

          <question-text-area-with-explanation
            v-model="question.queixaPrincipal"
            short-question="Queixa principal"
            explanation="Motivo da consulta, escrever com palavras do paciente"
          />

          <question-text-area-with-explanation
            v-model="question.historicoDeDoencaAtual"
            short-question="Histórico de Doença Atual (HDA)"
            explanation="Quando começou, qual é o problema, local, tipo, intensidade da dor, dor irradiada, o que faz a dor melhorar, o que desencadeia a dor, o que acompanha o sintoma, período da doença, neste instante como se encontra o problema"
          />

          <question-text-area-with-explanation
            v-model="question.examesComplementares"
            short-question="Exames complementares"
            explanation="Descreva dados importantes. Obs: manter cópia dos exames no prontuário do paciente."
          />

          <question-text-area-with-explanation
            v-model="question.estadoEmocional"
            short-question="Estado emocional"
            explanation="Como você se sente emocionalmente, problemas emocionais, traumas emocionais, conflitos de relacionamento"
          />

          <question-text-area-with-explanation
            v-model="question.historiaPatologicaPregressa"
            short-question="História Patológica Pregressa (HPP)"
            explanation="Doenças de infância, vacinação na infância, transfusão sanguínea, alergias, fraturas, cirurgias, problemas de pressão arterial, diabetes, neoplasias, Asma, bronquite, rinite, pneumonia, tuberculose, doenças sexualmente transmissíveis, hipo ou hipertireoidismo, problemas renais, infarto, marca passo, colesterol, trigliceres, obesidade, acidente vascular encefálico, hepatites, medicações, anticoncepcional"
          />

          <question-text-area-with-explanation
            v-model="question.historiaFisiologica"
            short-question="História Fisiológica"
            explanation="Tipo de parto que nasceu – domiciliar ou hospitalar, atraso de desenvolvimento, desenvolvimento e crescimento, primeira menstruação, primeira relação sexual, menopausa, numero de gestações, quantos filhos nasceram, abortos"
          />

          <question-text-area-with-explanation
            v-model="question.historiaFamiliar"
            short-question="História Familiar"
            explanation="Doenças familiar - hipertensão arterial, diabetes, acidente vascular cerebral, Infarto agudo do miocárdio e neoplasias, identificar geração e parentesco"
          />

          <question-text-area-with-explanation
            v-model="question.historiaSocial"
            short-question="História Social"
            explanation="Qual o consumo de bebidas alcoólicas, tabagismo, hábitos alimentares – qualidade e quantas vezes se alimenta por dia, hábitos de sono, ingestão de água, tipo de moradia, saneamento básico, quantas pessoas moram na casa, animais de estimação, hábitos de laser, viagens"
          />

          <question-text-area-with-explanation
            v-model="question.revisaoDosSintomas"
            short-question="Revisão dos Sintomas"
            explanation="Funcionamento geral intelectual e neurológico - “como está a cabeça”, se está dormindo bem, problemas de memória, queixas emocionais, como anda a visão, audição, funcionamento do sistema digestivo - problemas bucais, come bem, náuseas, emagrecimento, diarreia, constipação, funcionalidade do sistema, cardiorrespiratório - falta de ar, cansaço, tosse, funcionamento do si tema renal – ardência ou dor ao urinar, volume, cor da urina, funcionamento do sistema músculo esquelético – força, atividade física, qual atividade, quantas vezes por semana, sedentarismo, funcionamento sistema reprodutor – libido, potencia sexual, lubrificação"
          />
        </v-form>
        <div v-if="consentTermModelFile" class="mx-5">
          <perseu-input-file
            v-model="signedConsentTerm"
            label="Termo de consentimento assinado"
            input-accept="image/*,application/pdf"
          />
        </div>
      </v-card-text>
    </v-card>
    <step-buttons-therapy
      class="my-3"
      @click-next="submit"
      @click-back="back"
    />
  </v-container>
</template>

<script>
import WomanQuestions from "./WomanQuestions";
import QuestionTextAreaWithExplanation from "./QuestionTextAreaWithExplanation";
import PerseuInputFile from "@/domains/therapy/steps/questions/presentation/components/PerseuInputFile";
import { getConsentFile } from "@/services/local/settings-service";
import { findLastTherapyQuestions } from "@/services/external/therapies-service";

export default {
  components: {
    PerseuInputFile,
    QuestionTextAreaWithExplanation,
    WomanQuestions,
  },
  data: () => ({
    question: {
      utilizaDispositivoComBateriasNoCorpo: false,
      qualDispositivoComBateriasNoCorpo: "",
      possuiHistoricoDeCancer: false,
      descricaoHistoricoDeCancer: "",
      recebeuQuimioterapiaOuRadioterapia: false,
      quandoQuimioterapiaOuRadioterapia: "",
      mulher: false,
      possuiDiu: false,
      qualDiu: "",
      estaGravida: false,
      tempoDeGravidez: "",
      haChancesDeEstarGravida: false,
      possuiImplanteDeSilicone: false,
      utilizaMetodosContraceptivos: false,
      quaisMetodosContraceptivos: "",
      queixaPrincipal: "",
      historicoDeDoencaAtual: "",
      examesComplementares: "",
      estadoEmocional: "",
      historiaPatologicaPregressa: "",
      historiaFisiologica: "",
      historiaFamiliar: "",
      historiaSocial: "",
      revisaoDosSintomas: "",
      searchedQuestion: false,
    },
    consentTermModelFile: null,
  }),
  computed: {
    signedConsentTerm: {
      get() {
        return this.$store.state.therapy.therapy.signedConsentTerm;
      },
      set(newValue) {
        this.$store.commit("therapy/setSignedConsentTerm", newValue);
      },
    },
  },
  watch: {
    "question.mulher"(value) {
      if (!value) {
        this.question.possuiDiu = false;
        this.question.qualDiu = "";
        this.question.estaGravida = false;
        this.question.tempoDeGravidez = "";
        this.question.haChancesDeEstarGravida = false;
        this.question.possuiImplanteDeSilicone = false;
        this.question.utilizaMetodosContraceptivos = false;
        this.question.quaisMetodosContraceptivos = "";
      }
    },
    "question.estaGravida"(newValue) {
      if (newValue) {
        this.question.haChancesDeEstarGravida = false;
        this.question.possuiDiu = false;
        this.question.qualDiu = "";
        this.question.utilizaMetodosContraceptivos = false;
        this.question.quaisMetodosContraceptivos = "";
      } else {
        this.question.tempoDeGravidez = "";
      }
    },
  },
  created() {
    this.loadConsentTermModelFile();
  },
  mounted() {
    this.loadQuestions();
  },
  methods: {
    async loadQuestions() {
      if (this.$store.state.therapy.therapy.questionsSearched) {
        this.question = JSON.parse(
          JSON.stringify(this.$store.state.therapy.therapy.question)
        );
      } else {
        try {
          const patientEmail = this.$store.getters["therapy/patient"].email;
          const { data } = await findLastTherapyQuestions(patientEmail);
          this.question = data.question;
          this.$store.commit("therapy/saveQuestion", data.question);
          this.$store.commit("therapy/setQuestionsSearched", true);
        } catch (error) {
          this.$toasted.global.defaultError();
        }
      }
    },
    async submit() {
      try {
        /* const { data } = await create(this.question); */
        this.$store.commit("therapy/saveQuestion", this.question);
        this.$emit("next");
      } catch (error) {
        this.$toasted.global.defaultError();
      }
    },
    async loadConsentTermModelFile() {
      const response = await getConsentFile();
      if (response) {
        this.consentTermModelFile = new Blob([response.consentTerm], {
          type: "application/pdf",
        });
      }
    },
    async openConsentTermModel() {
      const fileURL = URL.createObjectURL(this.consentTermModelFile);
      window.open(fileURL);
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style></style>
